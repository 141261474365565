import { Box } from '../../shared-components/Box';
import { Block } from './Block';
import { useViewer, Viewer } from './ViewerProvider';

export const Column = ({ id }) => {
  const viewer = useViewer();
  const block = Viewer.getBlock(viewer, id);
  const defaultArrangement = !block?.properties?.arrangeBlocks || block?.properties?.arrangeBlocks === 'stretch-evenly';
  const width = 'width' in block?.properties ? block?.properties?.width * 100 : 100;
  return (
    <Box
      css={`
        width: ${width}%;
        height: auto;
        box-sizing: border-box;
        .arrange-block {
          transition: max-height 0.5s ease;
          width: 100%;
          ${defaultArrangement
            ? `height: 100%; max-height: 100%;`
            : `
              max-height: auto;
              :first-of-type {
                flex-grow: 1;
              }
              :last-of-type {
                flex-grow: 1;
              }
            `}
        }
      `}
      flex="column space-between"
    >
      {block?.children?.map((child) => (
        <Block id={child?.id} type={child.type} key={child?.id} />
      ))}
    </Box>
  );
};
